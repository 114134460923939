<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import Switches from "vue-switches";

import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { calendarEvents, categories } from "./data-calendar";

/**
 * Calendar component
 */
export default {
  page: {
    title: "Calendar",
    meta: [{ name: "description" }],
  },
  components: {
    FullCalendar,
    Layout,
    PageHeader,
    Switches,
  },
  data() {
    return {
      title: "Calendar",
      items: [
        {
          text: "HR",
        },
        {
          text: "Calendar",
          active: true,
        },
      ],
      calendarEvents: calendarEvents,
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: calendarEvents,
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        dateClick: this.dateClicked,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        type: "Holiday",
        start_date: "",
        end_date: "",
        annual: false,
        paid: false,
        religion: false,
      },
      editevent: {
        editTitle: "",
      },
    };
  },
  validations: {
    event: {
      title: { required },
      type: { required },
      start_date: { required },
      end_date: { required },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const title = this.event.title;
        const category = this.event.category;
        let calendarApi = this.newEventData.view.calendar;

        this.currentEvents = calendarApi.addEvent({
          id: this.newEventData.length + 1,
          title,
          start: this.newEventData.date,
          end: this.newEventData.date,
          classNames: [category],
        });
        this.successmsg();
        this.showModal = false;
        this.newEventData = {};
      }
      this.submitted = false;
      this.event = {};
    },
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      const editTitle = this.editevent.editTitle;
      const editcategory = this.editevent.editcategory;

      this.edit.setProp("title", editTitle);
      this.edit.setProp("classNames", editcategory);
      this.successmsg();
      this.eventModal = false;
    },

    /**
     * Delete event
     */
    deleteEvent() {
      this.edit.remove();
      this.eventModal = false;
    },
    /**
     * Modal open for add event
     */
    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.editcategory = this.edit.classNames[0];
      this.eventModal = true;
    },

    closeModal() {
      this.eventModal = false;
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar ref="fullCalendar" :options="calendarOptions">
              </FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      title="Add New Event"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <b-form-group
              label="Title"
              label-for="title"
              invalid-feedback="This value is required."
              :state="!$v.event.title.$error"
            >
              <b-form-input
                id="title"
                v-model="event.title"
                :state="$v.event.title.$dirty && !$v.event.title.$error"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group
              label="Type"
              label-for="type"
              invalid-feedback="This value is required."
              :state="!$v.event.type.$error"
            >
              <b-form-select
                id="type"
                v-model="event.type"
                size="sm"
                :state="$v.event.type.$dirty && !$v.event.type.$error"
                :options="['Holiday', 'Reminder']"
              ></b-form-select>
            </b-form-group>
          </div>
          <div v-if="event.type === 'Holiday'" class="col-12">
            <b-row>
              <b-col md="4">
                <b-form-group label="Is Annual?" label-for="annual">
                  <switches
                    v-model="event.annual"
                    type-bold="true"
                    class="mb-0"
                  ></switches>
                </b-form-group>
              </b-col>
              <b-col md="4" v-if="event.annual === false">
                <b-form-group label="Is Paid?" label-for="annual">
                  <switches
                    v-model="event.paid"
                    type-bold="true"
                    class="mb-0"
                  ></switches>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Is Religion Based?" label-for="religion">
                  <switches
                    v-model="event.religion"
                    type-bold="true"
                    class="mb-0"
                  ></switches>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="event.religion">
                <b-form-group label="Religion" label-for="religion">
                  <b-form-select
                    id="religion"
                    v-model="event.religion"
                    size="sm"
                    :options="['Muslims', 'Christians']"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="col-6">
            <b-form-group
              label="Start Date"
              label-for="start_date"
              invalid-feedback="This value is required."
              :state="!$v.event.start_date.$error"
            >
              <b-form-input
                id="start_date"
                v-model="event.start_date"
                type="date"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="End Date"
              label-for="end_date"
              invalid-feedback="This value is required."
              :state="!$v.event.end_date.$error"
            >
              <b-form-input
                id="end_date"
                v-model="event.end_date"
                type="date"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea name="note" />
            </b-form-group>
          </div>
        </div>

        <div class="text-right pt-5 mt-3">
          <b-button variant="light" @click="hideModal">Close</b-button>
          <b-button type="submit" variant="success" class="ml-1">
            Create
          </b-button>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      v-model="eventModal"
      title="Edit Event"
      title-class="text-black font-18"
      hide-footer
      body-class="p-0"
    >
      <form @submit.prevent="editSubmit">
        <div class="p-3">
          <label>Change event name</label>
          <div class="input-group m-b-15">
            <input
              v-model="editevent.editTitle"
              class="form-control"
              type="text"
            />
            <span class="input-group-append">
              <button type="submit" class="btn btn-success btn-md">
                <i class="fa fa-check"></i> Save
              </button>
            </span>
          </div>
        </div>
        <div class="text-right p-3">
          <b-button variant="light" @click="closeModal">Close</b-button>
          <b-button class="ml-1" variant="danger" @click="deleteEvent"
            >Delete</b-button
          >
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
